import $ from 'jquery'
import { Controller } from 'stimulus'
import { initCodeEditor } from '../shared/code_editor'

// bound events
import {
  EVENT_FLASHCARD_DELETE_DELETED,
  EVENT_FLASHCARD_DELETE_CANCELED,
} from '../shared/events'

// triggered events
import {
  EVENT_FLASHCARD_ADDED,
  EVENT_FLASHCARD_FORM_SAVED,
  EVENT_FLASHCARD_FORM_CANCELED,
  EVENT_FLASHCARD_FORM_DELETED,
} from '../shared/events'

export default class extends Controller {
  cancel() {
    $(this.element).trigger(EVENT_FLASHCARD_FORM_CANCELED).remove()
  }

  delete() {
    $(this.targetDeleteActions).addClass('d-none')
    this.flashcardDeleteController.show()
  }

  connect() {
    this.autoFocus()

    this.initCodeEditors()

    $(this.element)
      .on('ajax:success', this.targetFormSelector, (e) => {
        const [data, status, xhr] = event.detail
        this.onSaveSuccess(xhr.responseText)
      })
      .on('ajax:error', this.targetFormSelector, (e) => {
        const [data, status, xhr] = event.detail
        this.onSaveError(xhr)
      })

    $(this.element).on(EVENT_FLASHCARD_DELETE_CANCELED, () => {
      this.onDeleteCanceled()
    })

    $(this.element).on(EVENT_FLASHCARD_DELETE_DELETED, () => {
      this.onDeleted()
    })
  }

  autoFocus() {
    $(this.element).find('[autofocus]').focus()
  }

  initCodeEditors() {
    initCodeEditor(this.targetQuestion, 'test-question')
    initCodeEditor(this.targetAnswer, 'test-answer')
  }

  onSaveSuccess(html) {
    Turbolinks.clearCache()
    const $element = $(this.element).trigger(EVENT_FLASHCARD_FORM_SAVED, html)
    if (this.isNewFlashcard()) {
      $element.trigger(EVENT_FLASHCARD_ADDED)
    }
    $element.remove()
  }

  onSaveError(xhr) {
    if (xhr.status == 422) {
      this.replaceForm(xhr.responseText)
      this.initCodeEditors()
    } else {
      alert('Failed to save flashcard')
    }
  }

  onDeleteCanceled() {
    $(this.targetDeleteActions).removeClass('d-none')
  }

  onDeleted() {
    $(this.element).trigger(EVENT_FLASHCARD_FORM_DELETED).remove()
  }

  isNewFlashcard() {
    return !$(this.element).data('id')
  }

  replaceForm(html) {
    $(this.element).html($(html).html())
  }

  get targetFormSelector() {
    return this.targets.getSelectorForTargetName('form')
  }

  get targetDeleteActions() {
    return this.targets.find('delete-actions')
  }

  get targetFlashcardDelete() {
    return this.targets.find('flashcard-delete')
  }

  get targetQuestion() {
    return this.targets.find('question')
  }

  get targetAnswer() {
    return this.targets.find('answer')
  }

  get flashcardDeleteController() {
    return this.application.getControllerForElementAndIdentifier(
      this.targetFlashcardDelete,
      this.targetFlashcardDelete.getAttribute('data-controller')
    )
  }
}
