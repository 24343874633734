import $ from "jquery"
import { Controller } from "stimulus"

// triggered events
import {
  EVENT_TAG_FORM_SAVED,
  EVENT_TAG_FORM_CANCELED
} from '../shared/events'

export default class extends Controller {
  cancel() {
    $(this.element)
      .trigger(EVENT_TAG_FORM_CANCELED)
      .remove()
  }

  delete(e) {
    e.preventDefault()
    $(this.targetSaveActions).addClass('d-none')
    $(this.targetDeleteActions).removeClass('d-none')
  }

  cancelDelete(e) {
    e.preventDefault()
    $(this.targetSaveActions).removeClass('d-none')
    $(this.targetDeleteActions).addClass('d-none')
  }

  connect() {
    this.autoFocus()

    $(this.element).on('ajax:success', this.targetFormSelector, (e) => {
      const [data, status, xhr] = event.detail
      this.onSaveSuccess(xhr.responseText)
    }).on('ajax:error', this.targetFormSelector, (e) => {
      const [data, status, xhr] = event.detail
      this.onSaveError(xhr)
    })

    $(this.element).on('ajax:success', this.targetConfirmDeleteSelector, (e) => {
      const [data, status, xhr] = event.detail
      this.onDeleteSuccess()
    }).on('ajax:error', this.targetConfirmDeleteSelector, (e) => {
      const [data, status, xhr] = event.detail
      this.onDeleteError()
    })
  }

  onSaveSuccess(html) {
    $(this.element)
      .trigger(EVENT_TAG_FORM_SAVED, html)
      .remove()
  }

  onSaveError(xhr) {
    if (xhr.status == 422) {
      this.replaceForm(xhr.responseText)
    } else {
      alert('Failed to save tag')
    }
  }

  onDeleteSuccess() {
    $(this.element).remove()
  }

  onDeleteError() {
    alert('Faled to delete tag')
  }

  autoFocus() {
    $(this.element).find('[autofocus]').focus()
  }

  replaceForm(html) {
    $(this.element).html($(html).html())
  }

  get targetFormSelector() {
    return this.targets.getSelectorForTargetName('form')
  }

  get targetSaveActions() {
    return this.targets.find('save-actions')
  }

  get targetDeleteActions() {
    return this.targets.find('delete-actions')
  }

  get targetConfirmDeleteSelector() {
    return this.targets.getSelectorForTargetName('confirm-delete')
  }
}
