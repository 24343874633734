import $ from 'jquery'
import { Controller } from "stimulus"

export default class extends Controller {
  clearSearch() {
    const $query = $(this.targetQuery)
    $query.val('')
    $query.closest('form').submit()
  }

  connect() {
    $(this.element).on('ajax:success', this.targetShowMoreSelector, (e) => {
      const [data, status, xhr] = event.detail
      this.onShowMore(xhr.responseText)
    })
  }

  onShowMore(html) {
    $(this.targetShowMore).remove()
    $(this.targetContainer).append($(html).html())
  }

  get targetShowMoreSelector() {
    return this.targets.getSelectorForTargetName('show-more')
  }

  get targetShowMore() {
    return this.targets.find('show-more')
  }

  get targetContainer() {
    return this.targets.find('container')
  }

  get targetQuery() {
    return this.targets.find('query')
  }
}
