import $ from 'jquery'

window.dataLayer = window.dataLayer || [];
window.gtag = function() {
  dataLayer.push(arguments);
}
gtag('js', new Date());


function analyticsId() {
  return $('body').data('analytics-id')
}

$(document).on('turbolinks:load', () => {
  if (analyticsId()) {
    gtag('config', analyticsId(), { page_location: location.href });
  }
})
