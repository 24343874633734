import { Controller } from "stimulus"
import $ from 'jquery'
import {
    EVENT_FLASHCARD_FORM_CANCELED,
    EVENT_FLASHCARD_FORM_SAVED,
    EVENT_FLASHCARD_FORM_DELETED
} from '../shared/events'

export default class extends Controller {
  connect() {
    $(this.targets.find('edit'))
      .on('ajax:success', (e) => {
        const [data, status, xhr] = event.detail
        this.onEditSuccess(xhr.responseText)
      })
      .on('ajax:error', (e) => {
        const [data, status, xhr] = event.detail
        this.onEditError()
      })
  }

  onEditSuccess(formHtml) {
    const $form = $(formHtml)
      .on(EVENT_FLASHCARD_FORM_CANCELED, () => {
        this.onFormCanceled()
      })
      .on(EVENT_FLASHCARD_FORM_SAVED, (e, html) => {
        this.onFormSaved(html)
      })
      .on(EVENT_FLASHCARD_FORM_DELETED, (e) => {
        this.onDeleted()
      })
    $(this.element).after($form).addClass('d-none')
  }

  onEditError() {
    alert('Failed to edit flashcard')
  }

  onFormCanceled() {
    $(this.element).removeClass('d-none')
  }

  onFormSaved(flashcardHtml) {
   $(this.element).after(flashcardHtml).remove()
  }

  onDeleted() {
    $(this.element).remove()
  }
}
