import $ from 'jquery'
import { Controller } from "stimulus"

const CONTACT_TO = atob("bWFpbHRvOnN1cHBvcnRAcmVwZW1vLmNvbQ==")
const CONTACT = atob("c3VwcG9ydEByZXBlbW8uY29t")

export default class extends Controller {
  connect() {
    const $element = $(this.element)
    $element
      .attr('href', atob("bWFpbHRvOnN1cHBvcnRAcmVwZW1vLmNvbQ=="))
      .removeClass('d-none')
    if (!$element.text()) {
      $element.text(CONTACT)
    }
  }
}
