export const EVENT_FLASHCARD_FORM_SAVED    = 'repemo.flashcard-form:saved'
export const EVENT_FLASHCARD_FORM_DELETED  = 'repemo.flashcard-form:deleted'
export const EVENT_FLASHCARD_FORM_CANCELED = 'repemo.flashcard-form:canceled'

export const EVENT_FLASHCARD_DELETE_DELETED  = 'repemo.flashcard-delete:deleted'
export const EVENT_FLASHCARD_DELETE_CANCELED = 'repemo.flashcard-delete:canceled'

export const EVENT_FLASHCARD_ADDED = "repemo.flashcard:added"
export const EVENT_FLASHCARD_REPEATED = "repemo.flashcard:repeated"

export const EVENT_TAG_FORM_SAVED   = 'repemo.tag-form:saved'
export const EVENT_TAG_FORM_CANCELED = 'repemo.tag-form:canceled'
