import $ from 'jquery'
import { Controller } from "stimulus"

// bound events
import {
  EVENT_FLASHCARD_DELETE_DELETED,
  EVENT_FLASHCARD_FORM_SAVED,
  EVENT_FLASHCARD_FORM_CANCELED
} from '../shared/events'

// triggered events
import {
  EVENT_FLASHCARD_REPEATED
} from '../shared/events'

export default class extends Controller {
  showAnswer() {
    $(this.targetAnswer).removeClass('d-none')
    $(this.targetShowAnswer).addClass('d-none')
    $(this.targetScoring).removeClass('d-none')
    $(this.targetScoreCorrect).focus()
  }

  connect() {
    $(this.targetShowAnswer).focus()

    $(this.targetScoreCorrect).on('ajax:success', (e) => {
      const [data, status, xhr] = event.detail
      this.onScoreSuccess(xhr.responseText)
    })

    $(this.targetScoreWrong).on('ajax:success', (e) => {
      const [data, status, xhr] = event.detail
      this.onScoreSuccess(xhr.responseText)
    })

    $(this.targetEdit).on('ajax:success', (e) => {
      const [data, status, xhr] = event.detail
      this.onEditSuccess(xhr.responseText)
    })
  }

  onScoreSuccess(repetitionHtml) {
    Turbolinks.clearCache()
    const $newElement = $(repetitionHtml)
    $(this.element).replaceWith($newElement)
    $newElement.trigger(EVENT_FLASHCARD_REPEATED)
  }

  onEditSuccess(formHtml) {
    const $form = $(formHtml)
      .on(EVENT_FLASHCARD_FORM_CANCELED, () => {
        this.onFormCanceled()
      })
      .on(EVENT_FLASHCARD_FORM_SAVED, (e, html) => {
        this.onFormSaved(html)
      })
      .on(EVENT_FLASHCARD_DELETE_DELETED, (e) => {
        e.stopPropagation()
        this.onFlashcardDeleted()
      })
    $(this.element).after($form).addClass('d-none')
  }

  onFormCanceled() {
    $(this.element).removeClass('d-none')
  }

  onFormSaved(flashcardHtml) {
    this.reload()
  }

  onFlashcardDeleted() {
    this.reload()
  }

  reload() {
    Turbolinks.visit(document.location.pathname)
  }

  get targetAnswer() {
    return this.targets.find('answer')
  }

  get targetShowAnswer() {
    return this.targets.find('show-answer')
  }

  get targetScoring() {
    return this.targets.find('scoring')
  }

  get targetEdit() {
    return this.targets.find('edit')
  }

  get targetScoreCorrect() {
    return this.targets.find('score-correct')
  }

  get targetScoreWrong() {
    return this.targets.find('score-wrong')
  }
}
