import $ from "jquery"
import { Controller } from "stimulus"

// triggered events
import {
  EVENT_FLASHCARD_DELETE_DELETED,
  EVENT_FLASHCARD_DELETE_CANCELED
} from '../shared/events'

export default class extends Controller {
  show() {
    $(this.element).removeClass('d-none')
  }

  cancel() {
    $(this.element)
      .addClass('d-none')
      .trigger(EVENT_FLASHCARD_DELETE_CANCELED)
  }

  connect() {
    $(this.element).on('ajax:success', this.targetDeleteSelector, (e) => {
      const [data, status, xhr] = event.detail
      this.onDeleteSuccess()
    }).on('ajax:error', this.targetDeleteSelector, (e) => {
      const [data, status, xhr] = event.detail
      this.onDeleteError()
    })
  }

  onDeleteSuccess() {
    Turbolinks.clearCache()
    $(this.element).trigger(EVENT_FLASHCARD_DELETE_DELETED)
  }

  onDeleteError() {
    alert('Failed to delete flashcard')
  }

  get targetDeleteSelector() {
    return this.targets.getSelectorForTargetName('delete')
  }
}
