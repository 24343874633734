import $ from 'jquery'
import { Controller } from "stimulus"

// bound events
import {
  EVENT_TAG_FORM_SAVED,
  EVENT_TAG_FORM_CANCELED
} from '../shared/events'


export default class extends Controller {
  connect() {
    this.$targetEdit.on('ajax:success', (e) => {
      const [data, status, xhr] = event.detail
      this.onEditSuccess(xhr.responseText)
    })
  }

  onEditSuccess(formHtml) {
    const $form = $(formHtml)
    $form
      .on(EVENT_TAG_FORM_CANCELED, () => {
        this.onFormCanceled()
      })
      .on(EVENT_TAG_FORM_SAVED, (e, html) => {
        this.onFormSaved(html)
      })
    $(this.element).after($form).addClass('d-none')
  }

  onFormCanceled() {
    $(this.element).removeClass('d-none')
  }

  onFormSaved(flashcardHtml) {
   $(this.element).after(flashcardHtml).remove()
  }

  get $targetEdit() {
    return $(this.targets.find('edit'))
  }
}
