import $ from "jquery"
import { Controller } from "stimulus"
import {
    EVENT_FLASHCARD_ADDED,
    EVENT_FLASHCARD_DELETE_DELETED,
    EVENT_FLASHCARD_FORM_CANCELED,
    EVENT_FLASHCARD_FORM_SAVED
} from '../shared/events'

export default class extends Controller {
  clearSearch() {
    const $query = $(this.targetQuery)
    $query.val('')
    $query.closest('form').submit()
  }

  connect() {
    $(this.targetAdd).on('ajax:success', (e) => {
      const [data, status, xhr] = event.detail
      this.onAddSuccess(xhr.responseText)
    })

    $(this.element).on('ajax:success', this.targetShowMoreSelector, (e) => {
      const [data, status, xhr] = event.detail
      this.onShowMore(xhr.responseText)
    })

    $('body').on(EVENT_FLASHCARD_ADDED, () => {
      this.updateBadgeCount(1)
      $(this.targetSearchContainer).removeClass('d-none')
      $(this.targetEmptySearch).remove()
      $(this.targetAddFirstFlashcard).remove()
    })

    $('body').on(EVENT_FLASHCARD_DELETE_DELETED, () => {
      this.updateBadgeCount(-1)
    })
  }

  onAddSuccess(formHtml) {
    const $form = $(formHtml)
      .on(EVENT_FLASHCARD_FORM_CANCELED, () => {
        this.onNewFormCanceled()
      })
      .on(EVENT_FLASHCARD_FORM_SAVED, (e, html) => {
        this.onNewFormSaved(html)
      })
    $(this.targetAdd).after($form).addClass('d-none')
    $(this.targetAddFirstFlashcard).addClass('d-none')
  }

  onShowMore(html) {
    $(this.targetShowMore).remove()
    $(this.targetContainer).append($(html).html())
  }

  onNewFormCanceled() {
    $(this.targetAdd).removeClass('d-none')
    $(this.targetAddFirstFlashcard).removeClass('d-none')
  }

  onNewFormSaved(flashcardHtml) {
    $(this.targetContainer).prepend(flashcardHtml)
    $(this.targetAdd).removeClass('d-none')
  }

  updateBadgeCount(delta) {
    const $badgeCount = $(this.targetCountBadge)
    let count = $badgeCount.data('count')
    count = count + delta
    $badgeCount.data('count', count).text(count)
  }

  get targetAdd() {
    return this.targets.find('add')
  }

  get targetAddFirstFlashcard() {
    return this.targets.find('add-first-flashcard')
  }

  get targetShowMore() {
    return this.targets.find('show-more')
  }

  get targetShowMoreSelector() {
    return this.targets.getSelectorForTargetName('show-more')
  }

  get targetContainer() {
    return this.targets.find('container')
  }

  get targetCountBadge() {
    return this.targets.find('count-badge')
  }

  get targetQuery() {
    return this.targets.find('query')
  }

  get targetEmptySearch() {
    return this.targets.find('empty-search')
  }

  get targetSearchContainer() {
    return this.targets.find('search-container')
  }
}
