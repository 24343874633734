/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
import "shared/analytics"

import "core-js/fn/object/assign"
import "core-js/fn/set"
import "core-js/fn/map"
import "core-js/fn/promise"
import "core-js/fn/array/from"
import "core-js/fn/array/includes"
import "element-closest"

import "bootstrap"
import '../shared/auto_resize'

import { Application } from "stimulus"
import { autoload } from "stimulus/webpack-helpers"

const application = Application.start()
const controllers = require.context("../controllers", true, /\.js$/)
autoload(controllers, application)

import Rails from 'rails-ujs'
Rails.start()

import Turbolinks from 'turbolinks'
Turbolinks.start()

//import "shared/cookie_law"
