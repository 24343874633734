import $ from 'jquery'
import { Controller } from "stimulus"
import moment from 'moment-timezone'

export default class extends Controller {
  connect() {
    const time_zone = moment.tz.guess()
    if (time_zone) {
      $(this.element).val(time_zone)
    }
  }
}
