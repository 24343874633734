import $ from 'jquery'
import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    const $this = $(this.element)
    $this.tooltip()
      .on('shown.bs.tooltip', () => { $this.removeClass('text-muted') })
      .on('hidden.bs.tooltip', () => { $this.addClass('text-muted') })
  }
}
