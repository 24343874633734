import CodeMirror from 'codemirror/lib/codemirror.js'
import 'codemirror/mode/markdown/markdown'
import 'codemirror/mode/ruby/ruby'
import 'codemirror/mode/javascript/javascript'

function initCodeEditor(element, theme) {
  CodeMirror.fromTextArea(element, {
    mode: 'markdown',
    lineNumbers: true,
    tabSize: 2,
    indentUnit: 2,
    indentWithTabs: false,
    theme: `monokai repemo ${theme}`,
  })
}

export { initCodeEditor }
