import $ from 'jquery'
import { Controller } from "stimulus"
import braintree from 'braintree-web'
import dropin from 'braintree-web-drop-in'

export default class extends Controller {
  connect() {
    dropin.create({
        authorization: this.tokenizationKey,
        container: this.targetPaymentContainer
      }, (createErr, instance) => {
        if (createErr) {
          console.log('createErr:', createErr)
          return
        }

        this.targetBuy.addEventListener('click', (e) => {
          instance.requestPaymentMethod((requestPaymentMethodErr, payload) => {
            if (requestPaymentMethodErr) {
              console.log('requestPaymentMethodErr:', requestPaymentMethodErr)
              return
            }

            console.log('payload:', payload)
            this.checkout(payload)
          })
        })
      }
    )
  }

  checkout(payload) {
    if ($(this.targetBuy).attr('disabled')) {
      return
    }
    this.disableBuy()

    $.ajax({
      url: this.checkoutUrl,
      method: 'POST',
      dataType: 'json',
      data: {
        authenticity_token: this.authenticityToken,
        nonce: payload.nonce
      },
      success: (data) => {
        Turbolinks.clearCache()
        Turbolinks.visit(data.redirectUrl)
      },
      error: (xhr) => {
        if (xhr.status == 422) {
          const data = JSON.parse(xhr.responseText)
          Turbolinks.visit(data.redirectUrl)
        }
        else if (xhr.status == 409) {
          const data = JSON.parse(xhr.responseText)
          Turbolinks.visit(data.redirectUrl)
        }
        else {
          alert('Failed to complete the checkout.')
          Turbolinks.visit(location.pathname)
        }
      }
    })
  }

  disableBuy() {
    const $buy = $(this.targetBuy)
    $buy.data('original-text', $buy.text())
    $buy.text('Processing...').attr('disabled', 'true')
  }

  enableBuy() {
    const $buy = $(this.targetBuy)
    $buy.text($buy.data('original-text')).removeAttr('disabled')
  }

  get authenticityToken() {
    return $("meta[name='csrf-token']").attr('content')
  }

  get checkoutUrl() {
    return this.element.getAttribute('data-url')
  }

  get tokenizationKey() {
    return this.element.getAttribute('data-tokenization-key')
  }

  get targetBuy() {
    return this.targets.find('buy')
  }

  get targetPaymentContainer() {
    return this.targets.find('payment-container')
  }
}
