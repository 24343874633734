import $ from 'jquery'
import { Controller } from "stimulus"

export default class extends Controller {
  prev(e) {
    e.preventDefault()
    this.loadPage($(e.target).closest('[href]'))
  }

  next(e) {
    e.preventDefault()
    this.loadPage($(e.target).closest('[href]'))
  }

  connect() {
  }

  loadPage($link) {
    $.ajax({
      url: $link.attr('href'),
      dataType: 'html',
      success: (html) => {
        this.onLoadPageSuccess(html)
      },
      error: () => {
        alert('Failed to load schedule')
      }
    })
  }

  onLoadPageSuccess(html) {
    $(this.element).replaceWith(html)
  }
}
