import $ from 'jquery'
import "typeahead.js/dist/typeahead.jquery"
import Bloodhound from "typeahead.js/dist/bloodhound"
import { Controller } from "stimulus"
import itemTemplate from '../templates/tag_list_editor_item.handlebars'

function dashTokenizer(str) {
  return str.split(/\-/).concat(str)
}

export default class extends Controller {
  removeTagClicked(e) {
    e.preventDefault()
    this.removeTag(e.target)
  }

  connect() {
    const $element = $(this.element)
    const $hiddenTaglist = $(this.targetHiddenTaglist)
    const $addTagInput = $(this.targetAddTagInput)

    if ($hiddenTaglist.val()) {
      $hiddenTaglist.val().split(' ').forEach((tag) => {
        this.addTag(tag)
      })
    }

    this.typeAhead($addTagInput)

    $addTagInput.on('keydown', (e) => {
      if (e.keyCode == 13) { // enter
        this.onTagAdd($addTagInput)
        e.preventDefault()
      }
      else if (e.keyCode == 8 && !$addTagInput.typeahead('val')) {
        const tagItems = this.targetAllTagListItems
        if (tagItems.length > 0) {
          this.removeTag(tagItems[tagItems.length - 1])
        }
      }
    }).on('typeahead:select', (e) => {
      this.onTagAdd($addTagInput)
    })
  }

  onTagAdd($addTagInput) {
    const value = $addTagInput.typeahead('val').trim()
    if (this.validateTag(value)) {
      this.addTag(value)
    }
    $addTagInput.typeahead('val', '')
  }

  validateTag(value) {
    return value && !this.tagNames.includes(value)
  }

  addTag(tag) {
    const $list = $(this.tagContainer)
    const $item = $(itemTemplate({tag: tag})).tooltip()
    $list.append($item).append(' ')
    this.updateHiddenTagList()
  }

  removeTag(item) {
    $(item).tooltip('dispose').remove()
    this.updateHiddenTagList()
  }

  updateHiddenTagList() {
    const tagNames = this.tagNames
    $(this.targetHiddenTaglist).val(tagNames.join(' '))
    tagNames.length > 0 ? $(this.tagContainer).removeClass('d-none') : $(this.tagContainer).addClass('d-none')
  }

  typeAhead($addTagInput) {
    const tags = new Bloodhound({
      datumTokenizer: dashTokenizer,
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      prefetch: {
        url: $addTagInput.data('url'),
        cache: false
      }
    })

    $addTagInput.typeahead({
      hint: true,
      highlight: true,
      minLength: 1
    },
    {
      name: 'tags',
      source: tags
    })
  }

  get tagNames() {
    return this.targetAllTagListItems.map((item) => { return item.getAttribute('data-tag-name') })
  }

  get targetHiddenTaglist() {
    return this.targets.find('hidden-tag-list')
  }

  get targetAddTagInput() {
    return this.targets.find('add-tag-input')
  }

  get tagContainer() {
    return this.targets.find('tag-container')
  }

  get targetAllTagListItems() {
    return this.targets.findAll('tag-list-item')
  }
}
