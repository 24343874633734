import $ from 'jquery'
import { Controller } from "stimulus"

// bound events
import {
  EVENT_FLASHCARD_ADDED,
  EVENT_FLASHCARD_REPEATED,
  EVENT_FLASHCARD_DELETE_DELETED
} from '../shared/events'

export default class extends Controller {
  connect() {
    this.render()
    $(this.element).tooltip({
      title: () => { return $(this.element).data('title') }
    })

    const events = [
      EVENT_FLASHCARD_ADDED,
      EVENT_FLASHCARD_REPEATED,
      EVENT_FLASHCARD_DELETE_DELETED
    ].join(' ')
    $('body').on(events, () => {
      this.refresh()
    })
  }

  render() {
    const $element = $(this.element)
    const count = $element.data('count')

    $element.text(count)
    count > 0 ? $element.removeClass('d-none') : $element.addClass('d-none')
  }

  refresh() {
    const $element = $(this.element)

    $.getJSON($element.data('url'), (data) => {
      $element.data('count', data.count)
      $element.data('title', data.title)
      this.render()
    })
  }
}
