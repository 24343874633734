import $ from 'jquery'
import { Controller } from "stimulus"
import moment from 'moment-timezone'

export default class extends Controller {
  connect() {
    const time_zone = moment.tz.guess()
    if (time_zone) {
      const $element = $(this.element)
      $element.attr('href', $element.attr('href') + `?time_zone=${encodeURIComponent(time_zone)}`)
    }
  }
}
