import $ from 'jquery'

function autoResize(element) {
  element.style.height = 'auto'
  element.style.height = (element.scrollHeight) + 'px'
}

function autoResizeAll() {
  $('textarea').each(function() {
    autoResize(this)
  })
}

$(document).on('input', 'textarea', function() {
  autoResize(this)
})

$(document).on('turbolinks:load ajax:complete', autoResizeAll)
